<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th class="uk-width-medium">Fullname</th>
                            <th class="uk-width-small">Email</th>
                            <th class="uk-width-1-6">Phone</th>
                            <th>Preferensi<br>Domisili Kerja</th>
                            <th>Preferensi<br>Bahasa</th>
                            <th class="uk-text-center">Pendidikan</th>
                            <th class="uk-text-center">Pengalaman<br>Kerja</th>
                            <th class="uk-text-center">References</th>
                            <th class="uk-text-center">CV</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{data.nameFull}}</td>
                            <td>{{data.emailAddress}}</td>
                            <td>{{data.contactPhoneNumber}}</td>
                            <td>{{data.preferredWorkAddress}}</td>
                            <td>{{data.languageProficiencies}}</td>
                            <td class="uk-text-center">
                              <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.educationAchievements)">
                              <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                              <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.pastJobs)">
                              <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                              <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.references)">
                              <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                              <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.cvUri)">
                              <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div uk-dropdown="mode: hover" id="actionDropdown">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showModal('seekerDetailModal', data)">Detail</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="seekerDetailModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog" uk-overflow-auto>
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal()"></button>
                <span v-if="seeker_detail == null" uk-spinner="ratio: 2" class="uk-position-center uk-margin"></span>
                <div v-else-if="seeker_detail != null" class="uk-modal-body">
                    <div><h1><b>{{ seeker_detail.nameFull }}</b></h1></div>
                    <hr/>
                    <div>
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-3">
                                <span>Phone : {{ seeker_detail.contactPhoneNumber }}</span>
                            </div>
                            <div class="uk-width-2-3">
                                <span>Email : {{ seeker_detail.emailAddress }}</span>
                            </div>
                        </div>
                        <hr/>
                        <div v-if="seeker_detail.educationAchievements || seeker_detail.pastJobs">
                            <h2>Seeker Summary</h2>
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-3" v-if="seeker_detail.educationAchievements">
                                    <span>Latest education</span><br>
                                    <span>{{ seeker_detail.educationAchievements[0].school }}</span><br>
                                    <span>{{ seeker_detail.educationAchievements[0].degreeEarned }}</span>
                                </div>
                                <div class="uk-width-1-3" v-if="seeker_detail.pastJobs">
                                    <span>Last Job</span><br>
                                    <span>{{ seeker_detail.pastJobs[0].company }}</span><br>
                                    <span>{{ seeker_detail.pastJobs[0].jobTitle }}</span>
                                </div>
                                <div class="uk-width-1-3" v-if="seeker_detail.pastJobs">
                                    <span>Years of Professional Experience</span><br>
                                    <span>{{ getDuration(seeker_detail.pastJobs[(seeker_detail.pastJobs.length)-1].startTime) }}</span>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div v-if="seeker_detail.educationAchievements">
                            <h3>Education</h3>
                            <div v-for="(education, index) in seeker_detail.educationAchievements" :key="index" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-3">
                                    <span class="uk-text-bold">{{ education.school }}</span><br>
                                    <span>{{ education.degreeEarned }}</span>
                                </div>
                                <div class="uk-width-2-3">
                                    <span>{{ formatDate(education.startTime) }} - {{ formatDate(education.endTime) }}</span>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div v-if="seeker_detail.pastJobs">
                            <h3>Work Experience</h3>
                            <div v-for="(pastJob, index) in seeker_detail.pastJobs" :key="index" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-3">
                                    <span class="uk-text-bold">{{ pastJob.company }}</span><br>
                                    <span>{{ pastJob.jobTitle }}</span>
                                </div>
                                <div class="uk-width-2-3">
                                    <span>{{ formatDate(pastJob.startTime) }} - {{ formatDate(pastJob.endTime) }}</span><br>
                                    <span>{{ pastJob.description }}</span>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div v-if="seeker_detail.references">
                            <h3>References</h3>
                            <div v-for="(reference, index) in seeker_detail.references" :key="index" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-3">
                                    <span class="uk-text-bold">{{ reference.company }}</span><br>
                                    <span>{{ reference.position }}</span>
                                </div>
                                <div class="uk-width-2-3">
                                    <span>{{ reference.referenceType }}</span><br>
                                    <span>{{ reference.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";

export default {
    data(){
        return {
            images: PREFIX_IMAGE,
            meta: {
                search_by: null,
                search: '',
                start_date: null,
                end_date: null,
                sort_by: null,
                sort: 'desc',
                limit: 50,
                page: 1
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            seeker_detail: null
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getSeekersPaginate: 'kormo/getSeekersPaginate',
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getSeekersPaginate(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        dataExist(data) {
            if (!data) {
                return false;
            } else if (data.length < 1) {
                return false;
            } else {
                return true;
            }
        },
        async showModal(modal, data=null){
            await window.UIkit.dropdown('#actionDropdown').hide();
            switch (modal) {
            case 'seekerDetailModal':
                this.seeker_detail = data;
                await window.UIkit.modal('#seekerDetailModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'seekerDetailModal':
                await window.UIkit.modal('#seekerDetailModal').hide();
                this.seeker_detail = null;
                break;
            }
        },
        getDuration(data) {
            if(data) {
                let year = formatter.ageInYear(data);
                let monthTotal = formatter.monthTotal(data);
                let month = monthTotal%12;

                return `${year} years ${month} months`;
            } else {
                return "Not specified.";
            }
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.getMonthYear(data);
        }
    },
};
</script>
